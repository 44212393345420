import React from 'react'
import { graphql } from 'gatsby'
import 'twin.macro'

import Layout from '../components/layout'
import SEO from "../components/seo"


const Terms = ({data, location}) => {
  const siteTitle = data.site.siteMetadata.title
  const html = data.terms.contentLongText.childMarkdownRemark.html

  return (
    <Layout location={location} title={siteTitle} logoImage={data.logoImage}>
      <SEO title="Culture Yard Terms and Conditions" description={data.terms.description.description}/>
      <h1 tw="md:px-40 text-center">Terms and Conditions</h1>
      <section id="terms" tw="md:px-40 list-disc" dangerouslySetInnerHTML={{ __html: html }} />
    </Layout>
  )
}


export default Terms


export const data = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    logoImage:contentfulAsset(title: {eq: "logo"}) {
      fluid(quality: 100, maxWidth: 300) {
        ...GatsbyContentfulFluid
      }
    }
    terms:contentfulStaticPage(title: {eq: "Terms and Conditions"}) {
      title
      description {
        description
      }
      contentLongText {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
